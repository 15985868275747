// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check.js";
// console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
import './functions/fix-fullheight.js';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
import GraphModal from 'graph-modal';
const modal = new GraphModal({
  enableScrollLock: false,
  isOpen: (modal) => {
		disableScroll();
	},
  isClose: () => {
		enableScroll();
	}
});

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, FreeMode, EffectFade } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Scrollbar, FreeMode, EffectFade]);
const swiper = new Swiper('.result__swiper', {
  slidesPerView: '1.25',
  freeMode: true,
  spaceBetween: '16',
  breakpoints: {
    768: {
      slidesPerView: 2.5,
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar_result',
    draggable: true,
  },
});
const swiperDoctors = new Swiper('.doctors__swiper', {
  slidesPerView: '1.25',
  freeMode: true,
  spaceBetween: '16',
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1170: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: document.querySelector('.doctors-next-button'),
    prevEl: document.querySelector('.doctors-prev-button'),
  },
});
const swiperLicenses = new Swiper('.licenses__swiper', {
  slidesPerView: '1.25',
  freeMode: true,
  spaceBetween: '16',
  autoHeight: false,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1170: {
      slidesPerView: 4,
    },
    1360: {
      slidesPerView: 5,
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

const swiperEquipment = new Swiper('.equipment__swiper', {
  slidesPerView: '1.25',
  freeMode: true,
  spaceBetween: '16',
  breakpoints: {
    768: {
  slidesPerView: '1.25',

    },
    0: {
  slidesPerView: '1.1',

    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar_equipment',
    draggable: true,
  },
});

const swiperReviews = new Swiper('.reviews__swiper', {
  slidesPerView: '1.1',
  freeMode: true,
  spaceBetween: '16',
  autoHeight: true,
  breakpoints: {
    768: {
      slidesPerView: '1.2',
    },
    1070: {
      slidesPerView: '1.7',
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar_reviews',
    draggable: true,
  },
});

addEventListener("click", (e) => {
  const target = e.target.closest(".swiper-button-next_laboratory, .swiper-button-prev_laboratory");
  if (!target) return;

  const isNext = target.matches(".swiper-button-next_laboratory");
  const swiperEl = target.closest(".swiper");

  isNext ? swiperEl.swiper.slideNext() : swiperEl.swiper.slidePrev();
})
const swiperLaboratory = new Swiper('.laboratory__swiper', {
  slidesPerView: '1',
  freeMode: true,
  spaceBetween: '16',
  effect: 'fade',
  autoHeight: true,
  allowTouchMove: false
});

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

import { validateForms } from './functions/validate-forms.js';

validateForms('.form-1', [
  {
    ruleSelector: '.form-1 .input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.form-1 .input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
  {
    ruleSelector: '.form-1 .checkbox-group input',
    rules: [
       {
          rule: 'required',
          value: true,
          errorMessage: ' '
       }
    ]
 },
]);
validateForms('.form-2', [
  {
    ruleSelector: '.form-2 .input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.form-2 .input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
  {
    ruleSelector: '.form-2 .checkbox-group input',
    rules: [
       {
          rule: 'required',
          value: true,
          errorMessage: ' '
       }
    ]
  }
]);
validateForms('.form-3', [
  {
    ruleSelector: '.form-3 .input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.form-3 .input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
  {
    ruleSelector: '.form-3 .checkbox-group input',
    rules: [
       {
          rule: 'required',
          value: true,
          errorMessage: ' '
       }
    ]
  }
]);

import { burger } from './functions/burger.js';
import { useDynamicAdapt } from './functions/dynamic-adapt.js'

useDynamicAdapt()

import './functions/tabs.js';

import VenoBox from 'venobox';
import { disableScroll } from './functions/disable-scroll.js';
import { enableScroll } from './functions/enable-scroll.js';

const gallery1 = new VenoBox({
  selector: '.gallery1',
  numeration: true,
  infinigall: true,
  share: true,
  spinner: 'rotating-plane'
});

const galleryTeeths = new VenoBox({
  selector: '.galleryTeeths',
  numeration: true,
  infinigall: true,
  share: true,
  spinner: 'rotating-plane'
});


const galleryEquipment = new VenoBox({
  selector: '.galleryEquipment',
  numeration: true,
  infinigall: true,
  share: true,
  spinner: 'rotating-plane'
});

document.addEventListener('DOMContentLoaded', () => {

  // Получаем все ссылки с классом .scroll-link
  const scrollLinks = document.querySelectorAll('.scroll-link');

  // Обработчик события для каждой ссылки
  scrollLinks.forEach(link => {
      link.addEventListener('click', function(e) {
          e.preventDefault(); // Предотвращаем стандартное поведение ссылки

          const targetId = this.getAttribute('href'); // Получаем ID целевого блока
          const targetElement = document.querySelector(targetId); // Находим целевой элемент

          if (targetElement) {
              // Получаем позицию целевого элемента относительно всего документа
              const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;

              // Вычисляем конечную позицию прокрутки с учетом отступа 100 пикселей сверху
              const offsetPosition = elementPosition - 100;

              // Плавная прокрутка к целевому блоку
              window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth' // Параметр плавной прокрутки
              });
          }
      });
  });
});


export const addTokenToForms = function (form) {

  if (form === 'all') {

    const forms = document.querySelectorAll("form");

    forms.forEach(form => {
        let captchaField = form.querySelector('input[name="g_recaptcha_response"]');

        if (!captchaField) {
            captchaField = document.createElement("input");
            captchaField.type = "hidden";
            captchaField.name = "g_recaptcha_response";
            form.appendChild(captchaField);
        }

        grecaptcha.ready(() => {
            grecaptcha.execute('6LfBYJcqAAAAAMsy8WnZ30Ofdd8DQqGSXAiJi84G', { action: 'submit' }).then(token => {
                captchaField.value = token;
            });
        });
    });

  } else {
    let captchaField = form.querySelector('input[name="g_recaptcha_response"]');

    if (!captchaField) {
      captchaField = document.createElement("input");
      captchaField.type = "hidden";
      captchaField.name = "g_recaptcha_response";
      form.appendChild(captchaField);
    }

    grecaptcha.ready(() => {
      grecaptcha.execute('6LfBYJcqAAAAAMsy8WnZ30Ofdd8DQqGSXAiJi84G', { action: 'submit' }).then(token => {
        captchaField.value = token;
      });
    });

  }

};

grecaptcha.ready(() => {
  addTokenToForms('all');
})
